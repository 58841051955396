import React, { useEffect } from "react"
import Page from "../../components/page"
import { graphql } from "gatsby"
import pageStyles from "../../components/page.module.css"
import "./gallery.css"

export default function AlbumGallery({ location, data }) {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://code.jquery.com/jquery-3.5.1.slim.min.js"
    script.async = false

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "/js/main.js"
    script.async = false

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    const element = document.createElement("link")
    element.setAttribute("rel", "stylesheet")
    element.setAttribute("type", "text/css")
    element.setAttribute("href", "/css/main.css")
    document.getElementsByTagName("head")[0].appendChild(element)

    return () => {
      document.getElementsByTagName("head")[0].removeChild(element)
    }
  }, [])

  useEffect(() => {
    const element = document.createElement("link")
    element.setAttribute("rel", "stylesheet")
    element.setAttribute("type", "text/css")
    element.setAttribute("href", "/css/ionicons.min.css")
    document.getElementsByTagName("head")[0].appendChild(element)

    return () => {
      document.getElementsByTagName("head")[0].removeChild(element)
    }
  }, [])

  useEffect(() => {
    const element = document.createElement("link")
    element.setAttribute("rel", "stylesheet")
    element.setAttribute("type", "text/css")
    element.setAttribute("href", "/css/style.css")
    document.getElementsByTagName("head")[0].appendChild(element)

    return () => {
      document.getElementsByTagName("head")[0].removeChild(element)
    }
  }, [])

  useEffect(() => {
    const element = document.createElement("link")
    element.setAttribute("rel", "stylesheet")
    element.setAttribute("type", "text/css")
    element.setAttribute("href", "/css/modules.min.css")
    document.getElementsByTagName("head")[0].appendChild(element)

    return () => {
      document.getElementsByTagName("head")[0].removeChild(element)
    }
  }, [])

  useEffect(() => {
    const element = document.createElement("link")
    element.setAttribute("rel", "stylesheet")
    element.setAttribute("type", "text/css")
    element.setAttribute("href", "/css/linear.css")
    document.getElementsByTagName("head")[0].appendChild(element)

    return () => {
      document.getElementsByTagName("head")[0].removeChild(element)
    }
  }, [])

  return (
    <Page title={"Album Gallery – PhotoTailors"} location={location}>
      <div className={pageStyles.sectionWrapper}>
        <div className={pageStyles.section}>
          <div id='root' style={{margin: '35px 20px 20px 20px'}}></div>
        </div>
      </div>
    </Page>
  )
}


export const query = graphql`
  query {
    albumsHowOnline: file(relativePath: { eq: "albumsHowOnline.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   albumsHowHandmade: file(relativePath: { eq: "albumsHowHandmade.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsHowOrder: file(relativePath: { eq: "albumsHowOrder.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
   
   albumsHowShipping: file(relativePath: { eq: "albumsHowShipping.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  albumsHowTurnaround: file(relativePath: { eq: "albumsHowTurnaround.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  
   
  }
`